import { TextLink, type TextLinkRouterLinkElementProps } from "@naf/teamscheme";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { EVENTS } from "../Tracking";
import type { AppPages } from "../routing/AppPages";
import { getAbsolutePath } from "../routing/AppRoutes";
import { normalizeComponentTree } from "../shared/utils/normalizeComponentTree";

interface AboutTextLinkProps
  extends Omit<TextLinkRouterLinkElementProps, "target" | "linkTo"> {
  page: AppPages;
}

const AboutTextLink = styled(function (props: AboutTextLinkProps) {
  const { page, trackEvent, ...rest } = props;
  const { pathname } = useLocation();

  const isAboutRoute = pathname.startsWith("/om");

  return (
    <TextLink
      target={!isAboutRoute ? "_blank" : undefined}
      linkTo={getAbsolutePath(page)}
      trackEvent={
        trackEvent ?? {
          ...EVENTS.ABOUT_PAGE_BUTTONS.OPEN_ABOUT_PAGE,
          label: normalizeComponentTree(props.children),
          value: pathname,
        }
      }
      {...rest}
    />
  );
})``;

export default AboutTextLink;
